
$font-family: 'Roboto', sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
	height: 100%;
  // font-size: 16px;
  // font-weight: 400;
  // font-family: $font-family;
  background: linear-gradient(to right bottom, #f5f5f5, #e0e0e0) !important;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

input,
textarea,
select,
button {
  font-family: $font-family;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
